/* eslint-disable */
import { atom } from "jotai";
import {
  ICustomBadge,
  IGalleryName,
  IReviewLink,
  IService,
  IServicePackage,
  ITestimonial,
  IRedirect,
  IIndustryType,
} from "../types/types";

const globalState = atom({
  customerInfo: [],
  redirects: [] as IRedirect[],
  industry_types: [] as IIndustryType[],
  selected_industry_type: "",
  publish_to_google_storage: false,
  load_balancer_host: "",
  sitePk: 0 as number,
  fbSlug: "",
  cover_upload: "",
  id_key: "",
  multipage: true,
  scheduled_social_post_active: true,
  company_name: "",
  company_tagline: "",
  company_type: "",
  slug: "", // turn this into a ternery like so: route.params.slug ? route.params.slug : ''
  domain_purchased: false,
  is_dns_configured: false,
  hero_gallery_photos: [] as any,
  hero_image: "",
  // "hero_image": [],
  // "hero_type": '',
  // "unsplash": '',
  logo: "",
  logo_refined: "",
  use_refined_logo: false,
  custom_primary: "",
  // "shade":  '',
  custom_secondary: "",
  // "secondary_shade":  '',
  phone: "",
  email: "",
  license_number: "",
  insured: false,
  bonded: false,
  emergency: false,
  veteran: false,
  family: false,
  local: false,
  about: "",
  about_upload: "",
  // "about_us_type": '',
  twitter: "",
  instagram: "",
  facebook: "",
  google: "",
  // "snapchat": '',
  youtube: "",
  // "linkedin": '',
  nextdoor: "",
  tiktok: "",
  // "etsy": '',
  yelp: "",
  // "thumbtack": '',
  // "featured_video": '',
  street_address: "",
  city: "",
  state: "",
  zip_code: "",
  country: "",
  services: [] as IService[],
  testimonials: [] as ITestimonial[],
  faqs: [] as any,
  gallery: [] as any[],
  gallery_names: [] as IGalleryName[],
  about_header: "",
  service_header: "",
  service_subtitle: "",
  work_header: "",
  work_subtitle: "",
  partners_header: "",
  partners_subtitle: "",
  product_header: "",
  product_subtitle: "",
  testimonial_header: "",
  testimonial_subtitle: "",
  paid_url: "",
  github_repo: "",
  external_link: "",
  business_hours: [],
  service_area_array: [],
  service_areas: [],
  // "fbData": {} as any,
  galleries: [],
  fbData: {
    about: "",
    address: "",
    company_name: "",
    company_type: "",
    logo: "",
    phone: "",
    photos: [],
    extraPhotos: [],
    currentPulledPhotoCount: 0,
    services: [],
    socialMediaUrls: "",
    testimonials: [],
  },
  review_link: "",
  leave_review_link_header: "",
  review_link_more: "",
  yelp_review_link: "",
  other_review_link_header: "",
  see_more_reviews_header: "",
  alternative_review_html: "",
  cta_main: "",
  cta_main_1: "",
  cta_nav: "",
  cta_slide_in: "",
  cta_main_link: "",
  cta_main_1_link: "",
  cta_nav_link: "",
  cta_slide_in_link: "",
  call_to_action_message: "",
  call_to_action_message_main_1: "",
  call_to_action_message_nav: "",
  call_to_action_message_slide_in: "",
  // NEW FOR V2
  home_nav_title: "",
  service_nav_title: "",
  gallery_nav_title: "",
  reviews_nav_title: "",
  gallery_all_title: "",
  socials_header: "",
  three_step_header: "",
  three_step_subtitle: "",
  three_step_1_header: "",
  three_step_1_subtitle: "",
  three_step_2_header: "",
  three_step_2_subtitle: "",
  three_step_3_header: "",
  three_step_3_subtitle: "",
  payment_header: "",
  payment_subtitle: "",
  faq_header: "",
  team_header: "",
  team_subtitle: "",
  team_nav_title: "",
  jobs_header: "",
  jobs_subtitle: "",
  jobs_nav_title: "",
  cta_header: "",
  cta_subtitle: "",
  final_cta_header: "",
  final_cta_subtitle: "",
  call_to_action_message_nav_1: "",
  call_to_action_message_recruiting: "",
  cta_nav_1: "",
  cta_recruiting: "",
  cta_nav_1_link: "",
  cta_recruiting_link: "",
  cta_get_a_quote: "",
  cta_get_a_quote_link: "",
  call_to_action_message_get_a_quote: "",
  payments: [
    { title: "Venmo", link: "www.venmo.com" },
    { title: "PayPal", link: "www.paypal.com" },
    { title: "Zelle", link: "www.zelle.com" },
  ],
  awards: [],
  deal_header: "",
  deal_subtitle: "",
  deal_button_text: "",
  deal_button_link: "",
  teammates: [],
  team_col_1_header: "",
  team_col_1: "",
  team_col_2_header: "",
  team_col_2: "",
  black_owned: false,
  native_owned: false,
  woman_owned: false,
  latino_owned: false,
  bbb_attribute: false,
  leave_review_link_3_header: "",
  leave_review_link_3: "",
  thumbtack: "",
  bbb: "",
  homeadvisor: "",
  find_header: "", // this is used for service areas header
  jobs_description: "",
  // alternative_review_js: '',
  years_in_business_attribute: "",
  products: [], // this is used for partnerships
  version: "",
  show_jobs_page: false,
  show_team_page: false,
  team_show_image: true,
  text_show_address: true,
  text_show_attachment: false,
  text_show_email: true,
  text_show_full_name: true,
  text_show_message: true,
  text_message_placeholder: "How can we help?",
  text_show_phone: true,
  text_show_promo_code: false,
  text_show_requested_date: true,
  text_show_services: true,
  prophone_contact_id: "",
  gmb_account_id: "",
  google_analytics_tag: "",
  google_analytics_tag_body: "",
  so_tell_us_chat_code: "",
  custom_page: {
    custom_page: false,
    custom_page_tab_name: "",
    custom_page_header: "",
    custom_page_subheader: "",
    custom_page_body: "",
    custom_page_cta: "",
    custom_page_cta_link: "",
    custom_page_cta_secondary: "",
    custom_page_cta_secondary_link: "",
    custom_page_photo: "",
  },
  custom_layout: {},

  custom_hero_background_transparency: "25",
  custom_services_page_background_transparency: "25",
  custom_service_card_background_transparency: "25",
  custom_service_card_grayscale: true,
  custom_reviews_page_background_transparency: "25",
  custom_gallery_page_background_transparency: "25",
  custom_cta_background_transparency: "25",
  custom_final_cta_background_transparency: "25",
  custom_hero_text_color: "text-white",
  custom_services_page_text_color: "text-white",
  custom_reviews_page_text_color: "text-white",
  custom_gallery_page_text_color: "text-white",
  custom_cta_text_color: "text-white",
  custom_final_cta_text_color: "text-white",
  custom_navigation_background_color: "bg-white",
  custom_navigation_text_color: "text-gray-900",
  custom_navigation_transparent: false,
  custom_hero_background_color: "bg-black",
  custom_hero_slider: false,
  custom_hero_slider_type: "slide",
  custom_hero_bottom_curve: false,
  custom_hero_bottom_curve_type: "ellipse",
  custom_logo_size: "160",
  custom_hero_font: "",
  custom_all_font: "",
  customer_segment: "",
  service_link_text: "See more services",
  work_link_text: "See more photos",
  additional_phone_number_list: [],
  custom_badges: [] as ICustomBadge[],
  all_photos_cc_widget: "",
  home_cc_widget: "",
  announcement: {
    icon: "",
    show_announcement: false,
    description: "",
    background_color: "",
    text_color: "",
    icon_color: "",
  },
  form_header: "Send us a text",
  contact_chat_message: "Have a question?",
  embedded_form_for: "",
  embedded_form_show_full_name: true,
  embedded_form_show_phone: true,
  embedded_form_show_email: true,
  embedded_form_show_address: true,
  embedded_form_show_message: true,
  embedded_form_message_placeholder: "How can we help?",
  embedded_form_show_requested_date: true,
  embedded_form_show_services: true,
  embedded_form_show_promo_code: false,
  embedded_form_show_attachment: false,
  contact_form_steps: "1",
  two_steps_show_email: "2",
  two_steps_show_address: "1",
  two_steps_show_message: "1",
  two_steps_message_placeholder: "How can we help?",
  two_steps_show_requested_date: "2",
  two_steps_show_services: "2",
  two_steps_show_promo_code: "2",
  two_steps_show_attachment: "2",
  service_packages_array: [] as IServicePackage[], // TODO: Remove this
  review_links: [] as IReviewLink[],
  about_us_controls: false,
  opt_in_message: "",
  modified: "",
  send_sms_field_requirements: {},
  salesloft_id: "",
  selected_plan: "",
  primary_keyword: "",
  keywords: [] as string[],
  title_tag: "",
  meta_description: "",
});


export default globalState;
