/* eslint-disable */
import {
  borderedInputWhite,
  boxContainer,
  buttonWhiteSmall,
  label,
} from "../../../shared/styles";
import globalState from "../../../store";
import { useAtom } from "jotai";
import axios from "axios";
import { useEffect, useState, useContext } from "react";
import { AppContext } from "../../../App";
import GenericConfirmationPopUp from "../../../shared/GenericConfirmationPopUp";
import {
  checkDomainAvailability,
  configureDNSHelper,
  purchaseDomainHelper,
} from "../utils";

export default function DomainInfo() {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [isIndexed, setIsIndexed] = useState(false);
  const { slug, paid_url } = globalStateAtom;
  const { setIsSaveClicked, setAddOrChangePaidUrl } = useContext(AppContext);
  const [domainError, setDomainError] = useState("");
  const [buttonName, setButtonName] = useState("Check Domain Info");
  const [helpMessage, setHelpMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [dnsLoading, setDnsLoading] = useState(false);

  async function checkIfDomainIsAvailable(domain: string, slug: string) {
    // window.showGoogleDomainsFlow(domain, slug);
    setLoading(true);
    const response = await checkDomainAvailability(domain, slug);
    if (!response?.available) {
      setDomainError("Domain is not available.");
    } else {
      const { period, price } = response;
      setButtonName("Buy Domain Now");
      setHelpMessage(
        `Domain costs $${
          price / 1000000
        } per ${period} year. Do you want to proceed?`
      );
    }
    setLoading(false);
  }

  async function purchaseDomain(domain: string, slug: string) {
    // window.showGoogleDomainsFlow(domain, slug);
    setLoading(true);
    setHelpMessage("");
    const response = await purchaseDomainHelper(domain, slug);
    if (!response?.successfully_purchased) {
      setDomainError("Error purchasing domain.");
    } else {
      setGlobalState({ ...globalStateAtom, domain_purchased: true });
      setDomainError("");
      setButtonName("Check Domain Info");
    }
    setLoading(false);
  }

  function formatUrl(url) {
    let formattedUrl = url;
    if (formattedUrl.includes("http://"))
      formattedUrl = formattedUrl.split("http://")[1];
    if (formattedUrl.includes("https://"))
      formattedUrl = formattedUrl.split("https://")[1];
    if (formattedUrl.includes("/")) formattedUrl = formattedUrl.split("/")[0];
    return formattedUrl;
  }

  async function handleIndexing() {
    const indexed = await axios.get(
      `${process.env.REACT_APP_SERVER_URL}/api/index-google/${formatUrl(
        globalStateAtom.paid_url
      )}`
    );
    console.log(indexed.data.message.data, "indexed or reindexed successfully");
    setIsIndexed(indexed.data.message.data);
  }

  function handleClickingDomainButton() {
    if (buttonName === "Check Domain Info") {
      checkIfDomainIsAvailable(globalStateAtom.paid_url, slug);
    } else {
      purchaseDomain(globalStateAtom.paid_url, slug);
    }
  }

  async function handleConfigureDNS() {
    setDnsLoading(true);
    const response = await configureDNSHelper(globalStateAtom.paid_url, slug);
    if (response === "success") {
      setGlobalState({ ...globalStateAtom, is_dns_configured: true });
    }
    setDnsLoading(false);
  }

  function handleChangePaidUrl(e) {
    const newPaidUrl = e.target.value.replaceAll(" ", "");

    setDomainError("");
    setHelpMessage("");
    setButtonName("Check Domain Info");
    setIsSaveClicked(false);
    setAddOrChangePaidUrl(true);
    setGlobalState({
      ...globalStateAtom,
      paid_url: newPaidUrl,
    });
  }

  return (
    <>
      <h2 className="absolute top-32 left-24">
        Domain Info - and data_field - {globalStateAtom.sitePk}
      </h2>
      <div className={boxContainer}>
        <label className={label}>ProSite Slug - slug</label>
        <br />
        <input
          className={borderedInputWhite}
          onChange={(e) =>
            setGlobalState({
              ...globalStateAtom,
              slug: e.target.value,
            })
          }
          value={globalStateAtom.slug}
        />
        <br />

        <label className={label}>GitHub Repo - github_repo</label>
        <br />

        <input
          className={borderedInputWhite}
          onChange={(e) =>
            setGlobalState({
              ...globalStateAtom,
              github_repo: e.target.value.replaceAll(" ", ""),
            })
          }
          value={globalStateAtom.github_repo}
        />

        <br />

        <label className={label}>Domain - paid_url</label>
        <br />

        <input
          className={borderedInputWhite}
          onChange={handleChangePaidUrl}
          value={globalStateAtom.paid_url}
        />
        <div className="text-red-600 text-sm mt-1 text-left">{domainError}</div>
        <div className="text-red-600 text-sm mt-1 text-left">{helpMessage}</div>

        <div className="text-gray-500 mb-1">
          To buy domain, you must be on https://. If it says not secure in your
          browser, it will not work.
        </div>
        {globalStateAtom?.domain_purchased ? (
          <>Domain Purchased ✅</>
        ) : (
          <button
            className={`${buttonWhiteSmall} mt-6 ${
              buttonName === "Buy Domain Now" && "bg-green-200"
            }`}
            onClick={handleClickingDomainButton}
          >
            {loading ? (
              <div className="flex justify-center w-full">
                <img src="/loading.gif" width="25px" />
              </div>
            ) : (
              buttonName
            )}
          </button>
        )}

        {globalStateAtom?.is_dns_configured && (
          <div>DNS Configured ✅</div>
        )}
        <button
            className={`${buttonWhiteSmall} mt-6`}
            onClick={handleConfigureDNS}
          >
            {dnsLoading ? (
              <div className="flex justify-center w-full">
                <img src="/loading.gif" width="25px" />
              </div>
            ) : (
              <>Configure DNS Records</>
            )}
          </button>

        {isIndexed ? (
          <>Indexed ✅</>
        ) : (
          <button
            className={`${buttonWhiteSmall} max-h-16`}
            onClick={handleIndexing}
          >
            Index Site on Google
          </button>
        )}

        <div className="mt-1">Publish to: {globalStateAtom?.publish_to_google_storage ? 'Google' : 'Github Pages'}</div>
        {globalStateAtom?.publish_to_google_storage && (
          <div className='mt-1'>Load Balancer Host: {globalStateAtom?.load_balancer_host}</div>
        )}
      </div>
    </>
  );
}
