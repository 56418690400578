
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { borderedInputWhite } from "../../../shared/styles";
import globalState from "../../../store";
import { IGoogleIndustry } from "../../../types/types";

export const Industry: React.FC = () => {
  const [globalStateAtom, setGlobalState] = useAtom(globalState);
  const [industries, setIndustries] = useState<IGoogleIndustry[]>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<string>("");
  useEffect(() => {
    if (globalStateAtom && globalStateAtom.industry_types) {
      setIndustries(globalStateAtom.industry_types);
    }
    if (globalStateAtom && globalStateAtom.selected_industry_type) {
      setSelectedIndustry(globalStateAtom.selected_industry_type);
    }
  }, [globalStateAtom]);

  function handleChange(e) {
    setGlobalState({
      ...globalStateAtom,
      selected_industry_type: e.target.value,
    });
  }

  return (
    <div className="flex flex-col justify-start items-start bg-white p-6 rounded-lg mt-8">
      <div className="text-h1 font-bold">Industry Type</div>
      <div className="flex w-full pt-2">
        <select
          onChange={(e) => handleChange(e)}
          value={selectedIndustry || ""}
          className={`${borderedInputWhite}`}
          placeholder="Please select an industry"
        >
          <option
            value={""}
            disabled
            selected={!globalStateAtom?.selected_industry_type}
          >
            Please select an industry
          </option>
          {industries?.map((industry) => {
            return <option value={industry?.id}>{industry?.name}</option>;
          })}
        </select>
      </div>
    </div>
  );
}
